<!--
 * @Author: chenzhuo.li
 * @Date: 2024/11/29 14:41
 * @Description:菜谱页面 -> 商品详情弹窗——推荐加购商品弹窗
 -->
<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '60vh' }" v-if="show">
        <!-- 商品详情内容组件 -->
        <menu-item-detail-content :show="show" :item="item" :fullImg="false" :isRecommendPopup="true" @onClose="onClose" />
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onClose" v-if="show"></div>
    </transition>
  </div>
</template>
<script>
import MenuItemDetailContent from '@/views/order/MenuItemDetailContent.vue'

export default {
  name: 'MenuItemRecommendPopup',
  components: { MenuItemDetailContent },
  props: {
    show: { type: Boolean, required: true, default: false },
    item: { type: Object, required: true, default: () => {} },
    fullImg: { type: Boolean, required: false, default: true }
  },
  data() {
    return {}
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>
<style scoped lang="scss">
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  overflow: hidden;
  z-index: 2011;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2010;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}
</style>
