<!--
 * @Author: hwu
 * @Date: 2020-09-04 01:55:28
 * @Description: 菜谱页面 -> 商品详情弹窗
 * @LastEditTime: 2024-04-10 15:21:38
-->

<template>
  <div>
    <div class="wh-popup-wraper" @touchmove.stop>
      <transition name="wh-popup">
        <div class="wh-popup-box" :style="{ height: '80vh' }" v-if="show">
          <!-- 商品详情内容组件 -->
          <menu-item-detail-content :show="show" :item="item" :fullImg="fullImg" @onClose="onClose" @showRecommendItemDetailPopup="showRecommendItemDetailPopup" @openRecommendItemMoreSpecPopup="openRecommendItemMoreSpecPopup" />
        </div>
      </transition>
      <transition name="wh-backdrop">
        <div class="wh-popup-backdrop" @click="onClose" v-if="show"></div>
      </transition>
    </div>
    <!-- 推荐加购商品的详情弹窗 -->
    <menu-item-recommend-popup  :show="recommendPopupShow" :item="recommendItem" :fullImg="recommendFullImg" @onClose="recommendPopupShow = false" />
    <!-- 多规格商品的删减弹窗 -->
    <menu-item-more-spec-popup  :show="itemMoreSpecPopupShow" :recommendItemList="recommendItemList" :isRecommend="true" @onClose="itemMoreSpecPopupShow = false" />
  </div>

<!--  <div class="wh-popup-wraper" @touchmove.stop>-->
<!--    <transition name="wh-popup">-->
<!--      <div class="wh-popup-box" :style="{ height: '80%' }" v-if="show">-->

<!--      </div>-->
<!--    </transition>-->
<!--    <transition name="wh-backdrop">-->
<!--      <div class="wh-popup-backdrop" @click="onClose" v-if="show"></div>-->
<!--    </transition>-->

<!--  </div>-->
</template>
<script>
import MenuItemDetailContent from '@/views/order/MenuItemDetailContent.vue'
import MenuItemMoreSpecPopup from '@/views/order/MenuItemMoreSpecPopup.vue'
import MenuItemRecommendPopup from '@/views/order/MenuItemRecommendPopup.vue'
import { mapActions } from 'vuex'
export default {
  name: 'menu-item-popup',
  components: {
    MenuItemRecommendPopup,
    MenuItemMoreSpecPopup,
    MenuItemDetailContent

  },
  props: {
    show: { type: Boolean, required: true, default: false },
    item: { type: Object, required: true, default: () => {} },
    fullImg: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      recommendPopupShow: false,
      recommendItem: {},
      recommendFullImg: true,
      itemMoreSpecPopupShow: false,
      recommendItemList: []

    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.updaterRecommendCategoriesListVuex([])
        this.updaterRecommendMoreSpecListVuex([])
      }
    }
  },
  methods: {
    ...mapActions('order', ['updaterRecommendCategoriesListVuex', 'updaterRecommendMoreSpecListVuex']),
    showRecommendItemDetailPopup(item, fullImg) {
      this.recommendItem = item
      this.recommendFullImg = fullImg
      this.recommendPopupShow = true
    },
    openRecommendItemMoreSpecPopup(val) {
      this.recommendItemList = val.selectRecommendItem
      this.itemMoreSpecPopupShow = true
    },
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  overflow: hidden;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}
</style>
