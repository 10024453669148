<!--
 * @Author: hwu
 * @Date: 2020-08-28 12:16:31
 * @Description: 菜谱页面 -> 主页面
 * @LastEditTime: 2024-04-11 22:53:36
-->
<template>
  <div class="menu-wrapper">
    <div class="header-box">
      <div class="header-notice-box" @click="promoPopupShow = true" v-if="promoList && promoList.length > 0">
        <van-notice-bar class="notice-bar" :scrollable="false" background="none">
          <van-swipe vertical class="notice-swipe" :autoplay="5000" :show-indicators="false">
            <van-swipe-item class="notice-item" v-for="(promo, index) in promoList" :key="index">
              <span class="notice-tag">{{ promo.promoTag }}</span>
              <span>{{ promo.promoName }}；</span>
            </van-swipe-item>
          </van-swipe>
        </van-notice-bar>
        <div class="header-search-btn" @click.stop="searchPopupShow = true">
          <i class="iconfont iconsousuo"></i>
        </div>
      </div>
      <div class="header-search-input" v-else>
        <div class="input-mock" @click="searchPopupShow = true">
          <i class="iconfont iconsousuo"></i>
          <span>想吃什么，搜一搜</span>
        </div>
      </div>
    </div>
    <div class="menu-box" :class="{ 'with-footer': withFooter }">
      <div class="menu-tab-box" id="tab-box">
        <div class="single-tab-box" :id="'tab-' + category.categoryCode" v-for="(category, index) in tabList"
             :key="index" @click="changeCategory(category, index)">
          <div class="tab-content" :class="{ active: activeTabIndex === index }">{{ category.categoryName }}</div>
          <span class="tab-tag"
                v-show="tabSelectedQty(category.categoryCode) > 0">{{ tabSelectedQty(category.categoryCode) }}</span>
        </div>
      </div>
      <div class="menu-item-box" id="menu-item-box">
        <div class="fixed-category-title">
          <span class="category-title_text">{{ activeCategoryName }}</span>
        </div>
        <div class="category-box" v-for="(category, index) in menuItems" :key="index"
             :id="'item-' + category.categoryCode">
          <div class="category-anchor" :id="'anchor-' + category.categoryCode"></div>
          <div class="category-title">
            <span class="category-title_text">{{ category.categoryName }}</span>
            <!-- <span class="category-title_btn" v-show="activeTabIndex === index">
              <i class="btn-text">共同点餐</i>
            </span> -->
          </div>
          <menu-item-box :item="item" v-for="(item, itemIndex) in category.items" :key="itemIndex" @onDetail="showItemDetailPopup" @openItemMoreSpecPopup="openItemMoreSpecPopup" />
          <div class="category-placeholder"></div>
        </div>
      </div>
    </div>
    <!-- loading提示 -->
    <loading-box :show="loadingShow"/>
    <!-- 活动详情弹窗 -->
    <menu-promo-popup :show="promoPopupShow" :promos="promoList" @onClose="promoPopupShow = false"/>
    <!-- 搜索弹窗 -->
    <menu-search-popup :show="searchPopupShow" @onClose="searchPopupShow = false"/>
    <!-- 商品详情弹窗 -->
    <menu-item-popup :show="itemDetailPopupShow" :item="selectedItem" :fullImg="itemDetailPopupFullImg"
                     @onClose="itemDetailPopupShow = false"/>
    <!-- 购物车小球动画 -->
    <menu-item-ball-action/>
    <!--购物车商品变更弹窗提醒-->
    <dialog-box :show="cartItemChangeDialog" title="提示" rightBtnText="知道了" @onRightClick="cartItemChangeDialog=false">
      <div slot="body">
        <p >购物车内商品已变更！</p>
      </div>
    </dialog-box>
    <!-- 多规格商品的删减弹窗 -->
    <menu-item-more-spec-popup :show="itemMoreSpecPopupShow" :itemInfo="moreSpecItem" @onClose="itemMoreSpecPopupShow = false" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { NoticeBar, Swipe, SwipeItem } from 'vant'
import MenuPromoPopup from './MenuPromoPopup'
import MenuItemBox from './MenuItemBox'
import MenuItemPopup from './MenuItemPopup'
import MenuSearchPopup from './MenuSearchPopup'
import MenuItemBallAction from './MenuItemBallAction.vue'
import LoadingBox from '@/components/common/LoadingBox'
import { getCartInfoApi, getItemByBarCodeApi, getStoreMenuApi } from '@/api/cart'
import { listStorePromoTitleApi } from '@/api/promo'
import { closeSocketApi, connectSocketApi, subscribeCartApi } from '@/api/socket'
import { createScanDeviceConnectionApi } from '@/api/apk'
import DialogBox from '@/components/common/DialogBox.vue'
import MenuItemMoreSpecPopup from '@/views/order/MenuItemMoreSpecPopup.vue'

export default {
  name: 'menu-content',
  components: {
    MenuItemMoreSpecPopup,
    DialogBox,
    VanNoticeBar: NoticeBar,
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem,
    MenuPromoPopup,
    MenuItemBox,
    MenuItemPopup,
    MenuSearchPopup,
    MenuItemBallAction,
    LoadingBox
  },
  props: {
    withFooter: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      loadingShow: true,
      promoPopupShow: false,
      promoList: [],
      tabList: [],
      activeTabIndex: 0,
      activeCategoryName: '',
      searchPopupShow: false,
      itemDetailPopupShow: false,
      itemDetailPopupFullImg: true,
      selectedItem: {},
      serviceTypeOptions: [
        {
          code: 1,
          name: '堂食'
        },
        {
          code: 2,
          name: '外带'
        }
      ],
      // 购物车商品变更弹窗提醒
      cartItemChangeDialog: false,
      itemMoreSpecPopupShow: false,
      moreSpecItem: {}
    }
  },
  computed: {
    ...mapState('order', ['storeId', 'cartId', 'tableId', 'menuItems', 'serviceType', 'entranceType', 'userId', 'kdsModel']),
    tabSelectedQty() {
      return (categoryCode) => {
        const cateItems = this.menuItems.find((x) => x.categoryCode === categoryCode)
        let selected = 0
        if (!cateItems) {
          return selected
        }
        for (let item of cateItems.items) {
          if (item.quantity > 0) {
            selected += item.quantity
          }
        }
        return selected
      }
    }
  },
  watch: {
    menuItems(val, oldVal) {
      this.setTabList(val)
    }
  },
  created() {
    console.log('created')
    // 检查点餐环境
    this.$checkEntranceType(this.entranceType)
    // 获取门店菜谱
    this.getStoreMenu()
    // 获取门店活动
    this.listPromos()
    // 链接socket
    this.connectSocket()
    // 开启扫码枪连接
    createScanDeviceConnectionApi('2', this.cartId)
    // 监听扫码回调通知
    window.addEventListener('message', this.scanItemBarCodeCallBack, false)
    if (this.$bappSdk.isBApp()) {
      window.scanCallBack = this.scanItemBarCodeCallBack
    }
  },
  mounted() {
    console.log('mounted')
    document.getElementById('menu-item-box').addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    console.log('beforeDestroy')
    // 关闭扫码枪连接
    createScanDeviceConnectionApi('0', '')
    // 关闭扫码返回监听
    window.removeEventListener('message', this.scanItemBarCodeCallBack, false)
    // 关闭soket连接
    // this.closeSocket()
  },
  methods: {
    ...mapActions('order', ['initMenuItemsVuex', 'updateCartInfoVuex', 'addItemQtyVuex', 'updateServiceTypeVuex']),
    getStoreMenu() {
      getStoreMenuApi(this.cartId)
        .then((res) => {
          if (res.status !== 0) {
            this.$linkToError(res.msg)
            return false
          }
          this.loadingShow = false
          const itemList = res.data
          if (!itemList || itemList.length === 0) {
            const serviceTypeOption = this.serviceTypeOptions.find((x) => x.code === this.serviceType)
            const serviceTypeDesc = serviceTypeOption ? serviceTypeOption.name : '堂食'
            this.$linkToError(`当前门店无${serviceTypeDesc}菜谱`)
            return false
          }

          // 商品卖点数据
          this.setSellPointsStyle(itemList)
          // 将菜谱商品列表数据存入vuex
          // this.initMenuItemsVuex(itemList)
          // 获取购物车数据
          this.getCartInfo()

          // 处理左边导航栏的数据
          this.activeTabIndex = 0
          this.setTabList(itemList)
        })
        .catch(() => {
          this.$linkToError('点餐超时，请重新扫码下单')
        })
    },
    // 设置商品卖点样式数据
    setSellPointsStyle(itemList) {
      itemList.forEach((x) => {
        x.items.forEach((y) => {
          if (y.sellingPoints && y.sellingPoints.length > 0) {
            y.sellingPoints.forEach((z) => {
              if (z.type === 1) {
                z.styleData = `color:${z.colour}`
              } else if (z.type === 2) {
                z.styleData = `color:${z.colour};border-color:${z.colour}`
              } else if (z.type === 3) {
                z.styleData = `color:${z.colour};background:${this.$hexToRgba(z.colour, 0.12)}`
              } else {
                z.styleData = `background:${z.colour}`
              }
            })
          }
        })
      })
      // 将菜谱商品列表数据存入vuex
      this.initMenuItemsVuex(itemList)
    },
    // 处理左边导航栏的数据
    setTabList(val) {
      this.activeCategoryName = val[this.activeTabIndex].categoryName
      this.tabList = val.map((x) => {
        return {
          categoryCode: x.categoryCode,
          categoryName: x.categoryName
        }
      })
      // 这里需要等页面dom渲染完了之后，才能正常获取的距离顶部的高度
      this.$nextTick(() => {
        this.tabList.forEach((y) => {
          // 商品区域分类标签与顶部的距离，用于滚动的时候，判断当前处于哪个分类
          const itemOffsetTop = document.getElementById('item-' + y.categoryCode).offsetTop
          // 分类tab按钮与顶部的距离，用于滚动的时候，将激活的分类按钮放置于可视区域
          const tabOffsetTop = document.getElementById('tab-' + y.categoryCode).offsetTop
          y.itemOffsetTop = itemOffsetTop
          y.tabOffsetTop = tabOffsetTop
        })
      })
    },
    getCartInfo() {
      getCartInfoApi(this.cartId, this.userId).then((res) => {
        if (res.status !== 0) {
          this.$linkToError(res.msg)
          return false
        }
        const data = res.data
        this.updateServiceTypeVuex(data.serviceType || 1)
        if (this.serviceType === 5) {
          document.title = '抖音外卖'
        }
        // 将购物车数据存入vuex
        this.updateCartInfoVuex(data)
      })
    },
    listPromos() {
      listStorePromoTitleApi(this.storeId).then((res) => {
        this.promoList = res.data || []
      })
    },
    handleScroll() {
      // const scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
      const scrollTop = document.getElementById('menu-item-box').scrollTop
      let activeIndex = 0
      let activeCategoryName = ''
      let activeCategoryCode = ''
      let activeTabOffsetTop = 0
      for (let i = this.tabList.length - 1; i >= 0; i--) {
        if (scrollTop < this.tabList[i].itemOffsetTop - 10) {
          continue
        }
        activeIndex = i
        activeCategoryName = this.tabList[i].categoryName
        activeCategoryCode = this.tabList[i].categoryCode
        activeTabOffsetTop = this.tabList[i].tabOffsetTop
        break
      }
      if (this.activeTabIndex === activeIndex) {
        return false
      }
      this.activeTabIndex = activeIndex
      this.activeCategoryName = activeCategoryName
      const tabHeight = document.getElementById('tab-box').clientHeight - 200
      const tabScrollTop = document.getElementById('tab-box').scrollTop
      if (tabScrollTop > activeTabOffsetTop) {
        document.getElementById('tab-' + activeCategoryCode).scrollIntoView({ block: 'start', behavior: 'auto' })
        return
      }
      if (tabScrollTop + tabHeight < activeTabOffsetTop) {
        // document.getElementById('tab-' + activeCategoryCode).scrollIntoView({ block: 'end', behavior: 'auto' })
        document.getElementById('tab-box').scrollTop = activeTabOffsetTop - tabHeight
      }
    },
    changeCategory(category, index) {
      document.getElementById('anchor-' + category.categoryCode).scrollIntoView({ block: 'start', behavior: 'auto' })
      this.activeTabIndex = index
      this.activeCategoryName = category.categoryName
    },
    showItemDetailPopup(item, fullImg) {
      this.selectedItem = item
      this.itemDetailPopupShow = true
      this.itemDetailPopupFullImg = fullImg
    },
    openItemMoreSpecPopup(val) {
      this.moreSpecItem = val
      this.itemMoreSpecPopupShow = true
    },
    connectSocket() {
      connectSocketApi(this.cartId, this.userId).then(
        () => {
          subscribeCartApi(this.subscribeCart)
        },
        (err) => {
          console.log(err)
        }
      )
    },
    closeSocket() {
      closeSocketApi()
    },
    subscribeCart(cartInfo) {
      // 购物车商品有变更
      if (cartInfo.changed === 1) {
        this.cartItemChangeDialog = true
      }
      this.updateCartInfoVuex(cartInfo)
    },
    scanItemBarCodeCallBack(event) {
      let barCode = ''
      if (this.$bappSdk.isBApp()) {
        barCode = event
      } else {
        if (event.data.state !== 'scanCallBack') {
          return false
        }
        barCode = event.data.code
      }
      if (!barCode) {
        return false
      }
      getItemByBarCodeApi(this.cartId, this.userId, barCode).then((res) => {
        if (res.status !== 0 || !res.data) {
          this.$toast(res.msg)
          return false
        }
        const itemInfo = res.data
        // 称重商品，后端会自动加购购物车
        if (itemInfo.weighingStatus === 1) {
          this.$toast(itemInfo.itemName + '加购成功')
          return false
        }
        if (itemInfo.fixed === 0) {
          this.selectedItem = itemInfo
          this.itemDetailPopupShow = true
          return false
        }
        const itemData = {
          itemId: itemInfo.itemId,
          make: itemInfo.make,
          taste: itemInfo.taste,
          quantity: 1,
          barCode: itemInfo.barCode,
          standardStatus: itemInfo.standardStatus,
          isMake: itemInfo.isMake
        }
        const formData = {
          parentId: itemInfo.parentId,
          items: [itemData]
        }
        this.addItemQtyVuex(formData).then((res) => {
          if (res) {
            this.$toast(itemInfo.itemName + '加购成功')
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.header-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 750px;
  height: 80px;
  // padding: 12px 0;
  padding-bottom: 12px;
  background-color: $color-white;
  z-index: 11;

  .header-notice-box {
    display: flex;
    width: 100%;
    height: 68px;
    align-items: center;
    background-color: $color-background;

    .notice-bar {
      flex: 1;
      height: 56px;
      color: $color-text-normal;
      font-size: 24px;
      padding: 0 24px;

      .notice-swipe {
        height: 34px;
        line-height: 34px;
      }

      .notice-item {
        line-height: 34px;

        .notice-tag {
          display: inline-block;
          width: 34px;
          height: 34px;
          margin-right: 10px;
          line-height: 36px;
          text-align: center;
          font-size: 24px;
          color: $color-white;
          background: $color-danger;
          border-radius: 4px;
          transform: scale(0.84);
          transform-origin: center;
        }
      }
    }

    .header-search-btn {
      flex: 0;
      padding-right: 24px;

      i {
        font-size: 40px;
        color: $color-primary;
      }
    }
  }

  .header-search-input {
    width: 100%;
    padding: 0 90px;
    margin-top: 12px;

    .input-mock {
      width: 100%;
      height: 56px;
      line-height: 56px;
      text-align: center;
      font-size: 24px;
      color: $color-text-sub;
      background-color: #f5f5f5;
      border-radius: 56px;

      i {
        font-size: 32px;
        margin-right: 10px;
        vertical-align: bottom;
      }
    }
  }
}

.menu-box {
  position: relative;
  width: 100%;
  padding-top: 80px;
  background-color: $color-white;
  height: 100vh;
  overflow: hidden;

  &.with-footer {
    padding-bottom: 120px;
  }

  .menu-tab-box {
    position: fixed;
    top: 80px;
    left: 0;
    width: 160px;
    height: 100%;
    // padding-top: 80px;
    padding-bottom: 200px;
    background-color: $color-background;
    z-index: 11;
    overflow-y: scroll;

    .single-tab-box {
      position: relative;
      width: 100%;

      .tab-content {
        width: 100%;
        padding: 30px 20px 30px 40px;
        font-size: 24px;
        transform: scale(0.9);
        transform-origin: center left;

        &.active {
          color: $color-text-main;
          background-color: $color-white;
          font-size: 24px;
          transform: scale(1);
          font-weight: bold;

          &::before {
            content: '';
            position: absolute;
            top: 32px;
            bottom: 32px;
            left: 16px;
            border-left: 6px solid $color-primary;
            border-radius: 4px;
          }
        }
      }

      .tab-tag {
        position: absolute;
        display: inline-block;
        top: 24px;
        right: 16px;
        min-width: 44px;
        height: 44px;
        line-height: 44px;
        font-size: 24px;
        text-align: center;
        color: $color-white;
        background-color: $color-danger;
        border-radius: 50%;
        transform: scale(0.5);
        transform-origin: top right;
      }
    }
  }

  .menu-item-box {
    position: relative;
    padding-left: 160px;
    height: 100%;
    overflow-y: scroll;

    .fixed-category-title {
      position: fixed;
      display: flex;
      width: 750px;
      top: 79px;
      left: 0;
      padding: 26px 24px 26px 184px;
      align-items: center;
      font-size: 24px;
      color: $color-text-main;
      background-color: $color-white;
      font-weight: bold;
      z-index: 10;
      &::before {
        content: '';
        position: absolute;
        top: 32px;
        bottom: 24px;
        left: 184px;
        border-left: 6px solid $color-primary;
        border-radius: 4px;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 184px;
        right: 24px;
        border-top: 1px solid $color-border;
      }

      .category-title_text {
        flex: 1;
        padding-left: 24px;
        font-size: 30px;
      }

      .category-title_btn {
        position: relative;
        flex: 0;
        display: inline-block;
        height: 36px;
        padding: 0 7px;
        line-height: 32px;
        color: $color-primary;
        font-weight: 300;
        text-align: right;
        white-space: nowrap;
        border: 1px solid $color-primary;
        border-radius: 28px;

        .btn-text {
          display: inline-block;
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          transform: scale(0.8);
          transform-origin: center;
        }

        .btn-tag {
          position: absolute;
          display: inline-block;
          top: -8px;
          right: -8px;
          min-width: 44px;
          height: 44px;
          line-height: 44px;
          font-size: 24px;
          text-align: center;
          color: $color-white;
          background-color: $color-danger;
          border-radius: 50%;
          transform: scale(0.5);
          transform-origin: top right;
        }
      }
    }

    .category-box {
      position: relative;
      width: 100%;

      .category-anchor {
        position: absolute;
        top: 0px;
      }

      .category-title {
        // position: -webkit-sticky;
        // position: sticky;
        position: relative;
        display: flex;
        // top: 80px;
        width: 100%;
        padding: 26px 24px;
        margin-bottom: 8px;
        align-items: center;
        font-size: 24px;
        color: $color-text-main;
        background-color: $color-white;
        font-weight: bold;
        &::before {
          content: '';
          position: absolute;
          top: 32px;
          bottom: 24px;
          left: 24px;
          border-left: 6px solid $color-primary;
          border-radius: 4px;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 24px;
          right: 24px;
          border-top: 1px solid $color-border;
        }

        .category-title_text {
          flex: 1;
          padding-left: 24px;
          font-size: 30px;
        }

        .category-title_btn {
          flex: 0;
          display: inline-block;
          height: 36px;
          padding: 0 7px;
          line-height: 32px;
          color: $color-primary;
          font-weight: 300;
          text-align: right;
          white-space: nowrap;
          border: 1px solid $color-primary;
          border-radius: 28px;

          .btn-text {
            display: inline-block;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            transform: scale(0.8);
            transform-origin: center right;
          }
        }
      }
      .category-placeholder{
        width: 100%;
        height: 8px;
        background: $color-background;
      }
    }
  }
}
</style>
