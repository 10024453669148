<!--
 * @Author: chenzhuo.li
 * @Date: 2024/11/29 13:55
 * @Description:菜谱页面 -> 多规格商品的删减弹窗
 -->
<template>
  <w-popup v-model="show" position="bottom"  @close="onClose" :style="{ 'height': '60vh' }">
    <div class="wh-popup-container">
      <div class="wh-popup-body with-footer">
        <div class="wh-popup-content">
          <div class="content">
            <div class="single-item-box" v-for="(item, index) in itemList" :key="index">
              <div class="detail-header">
                <div class="detail-header_image">
                  <img class="item-image" :src="transferItemImage(item.image)" />
                </div>
                <div class="detail-header_info">
                  <div class="item-name-row">
                    <span>{{ item.itemName }}</span>
                  </div>
                  <div class="item-price-row">
                    <div class="item-price">{{ item.price | priceFilter }}</div>
                    <div class="item-btn-box">
                    <span class="item-btn minus-btn" v-if="item.quantity > 0" @click="minusItemQty(item, index)">
                      <i class="iconfont iconjianshao"></i>
                    </span>
                      <template v-if="item.weighingStatus === 1 && item.ownTakeStatus === 0 && !item.maxWeight">
                        <div class="item-qty">{{ item.weight }}{{ item.unitName }}</div>
                      </template>
                      <template v-else>
                        <div class="item-qty">{{ item.quantity }}</div>
                      </template>
                      <span class="item-btn add-btn" @click="addItemQty(item, index)">
                      <i class="iconfont iconzengjia-tianchong"></i>
                    </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- detailShowItemKey === item.uniKey -->
              <!-- detailShowItemKey === index -->
              <div class="detail-selected" :class="{ active: isActive(item, index) }" v-if="(item.subCharges && item.subCharges.length > 0) || (item.subItems && item.subItems.length > 0) || (item.itemSelectedDescList && item.itemSelectedDescList.length > 0)">
                <div class="detail-selected-box" @click="toggleCartItemDetail(item)">
                  <div class="detail-selected-content">
                    <span class="result-title">已选：</span>
                    <div class="result-content">
                      <template v-if="item.itemSelectedDescList && item.itemSelectedDescList.length > 0">
                        <span class="result-item" v-for="(desc, descIndex) in item.itemSelectedDescList" :key="descIndex">{{ desc }}</span>
                      </template>
                      <template v-else>
                        <span class="result-item" v-for="(sub, subIndex) in item.subItems" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                        <span class="result-item" v-for="(sub, subIndex) in item.subCharges" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                      </template>
                    </div>
                    <div class="collapse-btn"><i class="iconfont iconfangxiang-xiangxia"></i></div>
                  </div>
                </div>
                <div class="detail-selected-box_fixed" @click="toggleCartItemDetail(item)">
                  <div class="detail-selected-content">
                    <span class="result-title">已选：</span>
                    <div class="result-content">
                      <template v-if="item.itemSelectedDescList && item.itemSelectedDescList.length > 0">
                        <span class="result-item" v-for="(desc, descIndex) in item.itemSelectedDescList" :key="descIndex">{{ desc }}</span>
                      </template>
                      <template v-else>
                        <span class="result-item" v-for="(sub, subIndex) in item.subItems" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                        <span class="result-item" v-for="(sub, subIndex) in item.subCharges" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                      </template>
                    </div>
                    <div class="collapse-btn">
                      <i class="iconfont iconfangxiang-xiangshang"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail-selected" v-else>
                <div class="detail-selected-box">
                  <div class="detail-selected-content">
                    <span class="result-title">已选：</span>
                    <div class="result-content">
                      <span class="result-item" v-if="item.spec">{{ item.spec }}</span>
                      <span class="result-item" v-if="item.make">{{ item.make }}</span>
                      <span class="result-item" v-if="item.taste">{{ item.taste }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wh-popup-footer">
        <btn-footer showLeftBtn leftBtnText="返回" @onLeftClick="onClose" @onRightClick="onSubmit" />
      </div>
    </div>
  </w-popup>
</template>
<script>
import UUID from 'uuid-js'
import BtnFooter from '@/components/common/BtnFooter.vue'
import { getItemMoreSpecApi, updateCartItemMoreSpecApi } from '@/api/cart'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'MenuItemMoreSpecPopup',
  components: { BtnFooter },
  props: {
    show: { type: Boolean, required: true, default: false },
    itemInfo: { type: Object, required: false, default: () => {} },
    isRecommend: { type: Boolean, required: false, default: false },
    recommendItemList: { type: Array, required: false, default: () => [] }
  },
  data() {
    return {
      itemMoreSpecList: [],
      detailShowItemKey: ''
    }
  },
  watch: {
    show(val) {
      if (val) {
        if (this.isRecommend) {
          this.itemMoreSpecList = JSON.parse(JSON.stringify(this.recommendItemList))
          this.itemMoreSpecList.forEach((x) => {
            x.uuid = UUID.create(4).toString()
          })
        } else {
          this.getItemMoreSpec()
        }
      } else {
        this.itemMoreSpecList = []
        this.detailShowItemKey = ''
      }
    }
  },
  computed: {
    ...mapState('order', ['cartId', 'userId']),
    itemList() {
      return this.itemMoreSpecList.filter((x) => x.quantity)
    }
  },
  filters: {
    priceFilter(val) {
      if (val === 0) {
        return '免费'
      }
      return '￥' + val
    },
    cartSubItemFilter(val) {
      if (!val.itemName) {
        return ''
      }
      let descList = []
      if (val.spec) {
        descList.push(val.spec)
      }
      if (val.make) {
        descList.push(val.make)
      }
      if (val.taste) {
        descList.push(val.taste)
      }
      let descStr = ''
      if (descList.length > 0) {
        descStr = `(${descList.join(' ')})`
      }
      return `· ${val.itemName}${descStr} x ${val.quantity}`
    }
  },
  methods: {
    ...mapActions('order', ['updateCartInfoVuex', 'updaterRecommendMoreSpecListVuex']),
    minusItemQty(item, index) {
      if (this.isRecommend) {
        this.itemMoreSpecList.find(x => x.uuid === item.uuid).quantity = item.quantity - 1
      } else {
        this.itemMoreSpecList.find(x => x.uniKey === item.uniKey).quantity = item.quantity - 1
      }
      // this.$set(this.itemMoreSpecList[index], 'quantity', currentQuantity - 1)
      this.checkItemQuantity()
    },
    addItemQty(item, index) {
      const currentQuantity = item.quantity
      if (currentQuantity + 1 >= 99) {
        this.$toast('超过单品数量上限')
        return false
      }
      this.$set(this.itemMoreSpecList[index], 'quantity', currentQuantity + 1)
    },
    // 如果全部减掉，直接关闭弹窗
    checkItemQuantity() {
      if (this.itemMoreSpecList.every((x) => !x.quantity)) {
        this.onSubmit()
      }
    },
    isActive(item) {
      // 推荐加购的多规格删减
      if (this.isRecommend) {
        return this.detailShowItemKey === item.uuid
      } else {
        return this.detailShowItemKey === item.uniKey
      }
    },
    toggleCartItemDetail(item) {
      if (this.isRecommend) {
        if (this.detailShowItemKey === item.uuid) {
          this.detailShowItemKey = ''
          return
        }
        this.detailShowItemKey = item.uuid
      } else {
        if (this.detailShowItemKey === item.uniKey) {
          this.detailShowItemKey = ''
          return
        }
        this.detailShowItemKey = item.uniKey
      }
    },
    transferItemImage(key) {
      if (!key) {
        return require('@/assets/images/item-default.png')
      }
      return process.env.VUE_APP_QNY_LOAD_URL + key + '?imageView2/0/w/400'
    },
    getItemMoreSpec() {
      // this.cartId, this.userId, this.itemInfo.parentId
      //   '334daa55912b4636a187f56f2219de2e', 'oYtms5SyWHtWcQyuQdkaHK-MbBrk', '585049148034646016'
      getItemMoreSpecApi(this.cartId, this.userId, this.itemInfo.parentId).then((res) => {
        if (res.status !== 0 || !res.data) {
          this.$toast('暂无数据')
          return false
        }
        this.itemMoreSpecList = res.data.filter((x) => x.quantity)
      })
    },
    onSubmit() {
      // 多规格推荐加购商品删减
      if (this.isRecommend) {
        // 将多规格推荐加购商品删减弹窗的数据抛出
        this.updaterRecommendMoreSpecListVuex(this.itemMoreSpecList)
        this.onClose()
        return
      }
      // 多规格菜谱页商品删减
      const items = this.itemMoreSpecList.map((x) => {
        return {
          uniKey: x.uniKey,
          quantity: x.quantity
        }
      })
      const params = {
        cartId: this.cartId,
        userId: this.userId,
        items: items
      }
      updateCartItemMoreSpecApi(params).then((res) => {
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        const cartInfo = res.data
        this.updateCartInfoVuex(cartInfo)
        this.onClose()
      })
    },
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>
<style scoped lang="scss">
.content {
  background-color: $color-white;
  .single-item-box {
    padding-bottom: 16px;
  }
}
.detail-header {
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  padding: 24px 24px 0;
  margin-bottom: 32px;
  flex-wrap: nowrap;
  align-items: flex-start;
  &.full-mode {
    padding: 0;
    margin-bottom: 24px;
    flex-wrap: wrap;
    .detail-header_image {
      flex: 0 0 100%;
      margin-bottom: 24px;
      .item-image {
        width: 100vw;
      }
    }
    .detail-header_info {
      padding: 0 24px;
    }
  }
  .detail-header_image {
    flex: 0 0 216px;
    margin-right: 24px;
    .item-image {
      width: 216px;
      transition: width linear 0.5s;
    }
    .item-image-fix-height {
      height: 450px;
    }
  }
  .detail-header_info {
    flex: 1;
    .item-name-row {
      margin-bottom: 24px;
      line-height: 32px;
      font-size: 30px;
      font-weight: bold;
      color: $color-text-main;
      .item-unit {
        margin-left: 8px;
      }
    }
    .item-rank-row {
      margin-bottom: 36px;
      font-size: 24px;
      color: $color-text-sub;
      .item-rank-item:not(:last-child) {
        margin-right: 20px;
      }
    }
    .item-price-row {
      display: flex;
      width: 100%;
      align-items: center;
      .item-price {
        flex: 1;
        color: $color-danger;
        font-size: 30px;
        .item-price_normal {
          font-size: 24px;
          color: $color-text-normal;
        }
        .item-price_desc {
          font-size: 24px;
          color: $color-text-sub;
        }
        .item-price_origin {
          margin-left: 16px;
          font-size: 24px;
          color: $color-text-sub;
          text-decoration: line-through;
        }
      }
      .item-btn-box {
        flex: 0;
        display: flex;
        align-items: center;
        .item-btn {
          font-size: 0;
          .iconfont {
            color: $color-primary;
            font-size: 48px;
          }
          &.disabled {
            opacity: 0.5;
          }
        }
        .item-qty {
          display: inline-block;
          min-width: 60px;
          white-space: nowrap;
          font-size: 26px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}
.detail-selected {
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  padding: 0 24px;
  //   margin-bottom: 48px;
  font-size: 24px;
  &.active {
    .detail-selected-box {
      visibility: hidden;
    }
    .detail-selected-box_fixed {
      max-height: 682px;
      opacity: 1;
    }
  }
  .detail-selected-box {
    position: relative;
    width: 100%;
    .detail-selected-content {
      position: relative;
      display: flex;
      width: 100%;
      line-height: 30px;
      padding: 16px 42px 16px 12px;
      background-color: #f3f6fa;
      .result-content {
        height: 30px;
        line-height: 30px;
        overflow: hidden;
      }
    }
    .collapse-btn {
      position: absolute;
      top: 16px;
      right: 30px;
      z-index: 11;
    }
  }
  .detail-selected-box_fixed {
    position: absolute;
    display: block;
    max-height: 0;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 24px;
    overflow: hidden;
    transition: all 0.5s;
    opacity: 0;
    z-index: 20;
    .detail-selected-content {
      position: relative;
      display: flex;
      width: 100%;
      max-height: 650px;
      padding: 16px 12px 48px 12px;
      line-height: 36px;
      background-color: #f3f6fa;
      overflow: hidden;

      .collapse-btn {
        position: absolute;
        bottom: 10px;
        right: 30px;
        z-index: 21;
      }
      .result-content {
        max-height: 586px;
        overflow: scroll;
      }
    }
  }
  .result-title {
    flex: 0;
    white-space: nowrap;
  }
  .result-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .result-item {
      flex: 0 0 auto;
      padding: 0 12px;
      margin-bottom: 8px;
      white-space: nowrap;
    }
  }
}
</style>
